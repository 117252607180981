@import url(style.css);


.main {
  display: grid;
  width: 100vw;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  height: -webkit-fill-available;
  height: 100dvh;
  user-select: none;

}

.SVG-cover {
  grid-row: 1/6;
  grid-column: 1/5;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border-bottom-right-radius: 10px;
  box-shadow: inset -10px -10px 1vw var(--Box-shadow);
}
.visual {
  width: 100%;
  height: 100%;
  grid-column: 1/2;
  grid-row: 1/2;

  
  /* width: max(76vw, 25em);
  height: 78vh; */
}
.Bottom{
  grid-row: 6/7;
  grid-column: 1/6;
  align-self: center;
  justify-self: start;
  height: 100%;
  width: 100%;
  padding: 0 1rem;
}
.Bottom-Bar {
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  color: white;
  background-color: var(--Main-theme);
}
.Side{
  grid-row: 1/6;
  grid-column: 5/6;
  border-bottom: 2px solid var(--Border);
  padding: 1rem 0 1rem 0;
}

.SVG {
  border-radius: 20px;
  height: 100%;
  width: 100%;
  background-color: var(--Main-theme);
  
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  /* margin: 0 20px; */
}
.alert {
  display: flex;
  justify-content: center;
  place-items: center;
  width: 19rem;
  z-index: 10;
  position: fixed;
  left: 10dvw;
  top: 30px;
}
.DownloadOptions{
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  width: 20rem;
  z-index: 10;
  position: fixed;
  height: 8rem;
  background-color: var(--Secondary-main-theme);
  border-radius: 7px;
  box-shadow: 1px -3px 1vw var(--Box-shadow);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -100%);
}
.Options{
  margin-top: 3rem;
  display: flex;
  gap: 40px;
}
.DownloadTopbar{
  position: absolute;
  top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  place-items: center;
  justify-content: space-around;
}
.DownloadTitle{
  color: var(--Element-main-theme);
}
.CloseDownload{
  background-color: transparent;
  cursor: pointer;
}
/* @media (prefers-reduced-motion: no-preference) {
  .visual-path {
    animation: spin infinite 10s linear;
  }
} */

@keyframes spinBack {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Filling-Text {
  color: var(--Element-main-theme);
}

@media only screen and (max-width: 50rem) {
  .main {
    display: grid;
    width: 100vw;
    height: 100dvh;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    height: -webkit-fill-available;
  }
  

  .SVG-cover {
    grid-row: 1/6;
    grid-column: 1/5;
    width: 100%;
    height: 100%;
    padding: 1rem;
    border-bottom-right-radius: 10px;
    box-shadow: inset -10px -10px 1vw var(--Box-shadow);
  }

  .Bottom{
    grid-row: 6/8;
    grid-column: 1/6;
    align-self: center;
    justify-self: start;
    height: 100%;
    width: 100%;
    padding-left: 1rem;
  }
  .Side{
    grid-row: 1/6;
    grid-column: 5/6;
    border-bottom: 2px solid var(--Border);
    
  }

  .SVG {
    border-radius: 20px;
    height: 100%;
    width: 100%;
    background-color: var(--Main-theme);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .Filling-Text{
    font-size: 18px;
  }
}


