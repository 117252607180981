.Side-Bar {
  width: 100%;
  background-color: var(--Main-theme);
  height: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.Layers-container {
  grid-row: 1/6;
  grid-column: 1/3;
  height: 100%;
  width: 100%;
  color: white;
  box-sizing: border-box;
}

.Layer-title {
  width: 100%;
  display: flex;
  justify-content: center;
}
.AddDownloadBtnDiv {
  display: grid;
  grid-template-rows: 2rem 2rem;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  place-items: center;
  gap: 1rem;
  width: 100%;
  grid-column: 1/3;
  grid-row: 6/7;
  justify-self: end;
  align-self:end ;
  padding: 0.5rem 0.5rem 0 0.5rem;
  /* position: fixed;
  bottom: 0;
  bottom: min(1rem, 2vh); */
}
.bgColor{
  grid-row: 1/2;
  grid-column: 1/3;
  width: 100%;
  white-space: nowrap;

}
.backColor{
  cursor: pointer;
  display: flex;
  gap: 1vw;
  color: white;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 100%;
  font-size: 1rem;
  height: 1.3rem;
}
.colorSquare{
  box-sizing: border-box;
  border: 2px black solid;
  width: 1rem;
  height: 1rem;
}
.AddLayerBtn,
.DownloadBtn {
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  place-items: center;
  font-size: 1rem;
  gap: 5px;
  width: 100%;
  height: 100%;
  background-color: var(--Element-main-theme);
  color: var(--Main-theme);
  white-space: nowrap;
  text-decoration: none;
}
.AddLayerBtn{
  grid-row: 2/3;
  grid-column: 1/2;
}
.DownloadBtn{
  grid-row: 2/3;
  grid-column: 2/3;
}
.AddLayerIcon,
.DownloadIcon {
  color: black;
  background-color: transparent;
}
.AddLayerBtn:hover,
.DownloadBtn:hover{
  background-color: var(--Element-secondary-theme);
}
.backColor:hover {
  color: rgb(207, 207, 207);
}
.backColor:active {
  color: rgb(190, 190, 190);
}
.AddLayerBtn:active,
.DownloadBtn:active {
  background-color: var(--Element-hi-light);
}

.coverSide {
  z-index: 3;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.color-pickerSide {
  z-index: 4;
  position: absolute;
  translate: -50% -110%;
}

@media only screen and (max-width: 80rem) {
  .AddDownloadBtnDiv {
    display: grid;
    grid-template-rows: 2rem 2rem;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    place-items: center;
    gap: 1rem;
    grid-column: 1/3;
    grid-row: 6/7;
    justify-self: end;
    align-self: end;
    padding: 0.5rem 0.5rem 0 0.5rem;
    font-size: 1vw;
  }
  .AddLayerBtn,
  .DownloadBtn {
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    place-items: center;
    font-size: 1rem;
    gap: 5px;
    height: 2rem;
    background-color: var(--Element-main-theme);
    font-size: 1.3vw;

  }
  
}

@media only screen and (max-width: 60rem) {
  .AddDownloadBtnDiv {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    justify-content: center;
    place-items: center;
    gap: 1rem;
    grid-column: 1/3;
    grid-row: 6/7;
    justify-self: end;
    align-self: end;
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
  .AddLayerBtn,
  .DownloadBtn {
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    place-items: center;
    gap: 5px;
    height: 100%;
    background-color: var(--Element-main-theme);
    font-size: min(2.2vw,18px);
  }
  .AddLayerBtn{
    grid-row: 2/3;
    grid-column: 1/2;
  }
  .DownloadBtn{
    grid-row: 3/4;
    grid-column: 1/2;
  }
  .backColor{
    /* font-size: 18px; */
    font-size: min(2.4vw,18px);
    white-space: pre-line;
    
  }
  .bgColor{
    grid-row: 1/2;
    grid-column: 1/2;
    width: 100%;
    white-space: nowrap;
  
  }

  .colorSquare{
    grid-column: 1/2;
  }
}
@media only screen and (max-width: 50rem) {
  .Side-Bar {
    /* width: 100%; */
    background-color: var(--Main-theme);
    z-index: 1;
    height: 100%;
  }
  .colorSquare{
    box-sizing: border-box;
    border: 2px black solid;
    width: 0.2rem;
    height: 0.2rem;
  }
  .color-pickerSide {
    z-index: 4;
    position: absolute;
    translate: -100% -5%;
  }
}


@media only screen and (max-width: 40rem) {
  .Side-Bar {
    /* width: 100%; */
    background-color: var(--Main-theme);
    z-index: 1;
    height: 100%;
  }
  .DownloadIcon,
  .AddLayerIcon{
    visibility: hidden;
    position: fixed;
  }
  .AddLayerBtn,
  .DownloadBtn {
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    place-items: center;
    gap: 5px;
    font-weight: 700;
    height: 100%;
    background-color: var(--Element-main-theme);
    font-size: min(2.8vw,26px);
  }
}