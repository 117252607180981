.Bottom-Bar {
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  color: white;
  background-color: var(--Main-theme);
  display: flex;
  gap: 8vw;

}
.Canvas-W-H {
  width: 16rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: max(1.7vh, 1rem);
  gap: 20px;
}
.Rotation-Title{
  margin-bottom: max(1.7vh, 1rem);
}
.var-inputs {
  color: var(--Element-main-theme);
  font-size: 1rem;
  padding-left: 0.2rem;
  width: 5em;
  background-color: var(--Secondary-main-theme);
}
.size:hover {
  background-color: var(--Element-hi-light);
}
.input-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.W-size,
.strokeWidth,
.segments {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
h1 {
  font-size: 1.3em;
}
.Svg-Variables{
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: max(1.7vh, 1rem);
}
.Variables {
  font-size: 1em;
  display: flex;
  justify-content: space-between;
  width: 15rem;
  color: var(--Element-main-theme);
  font-weight: 700;
  appearance: none;
}
.Rotation {
  font-size: 1em;
  display: flex;
  justify-content: space-between;
  width: 15rem;
  color: var(--Element-main-theme);
  font-weight: 700;
  margin-bottom: max(2vh, 1rem);
  appearance: none;
}
.Variable-Input {
  appearance: none;
  background-color: transparent;
}
.Variable-Input::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  background-color: var(--Element-main-theme);
  border-radius: 4px;
  height: 0.5rem;
  -webkit-appearance: none;
}
.Variable-Input::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  margin-top: -4px;
  background-color: var(--Element-secondary-theme);
}
.Variable-Input:disabled::-webkit-slider-runnable-track {
  background-color: var(--Secondary-main-theme);
}
.Variable-Input:disabled::-webkit-slider-thumb {
  background-color: var(--Secondary-main-theme);
}

.Direction-Buttons {
  display: flex;
  gap: 2rem;
  width: 8rem;
  justify-content: center;
}

.Direction-Button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}
.Direction-Icon {
  width: 100%;
  height: 100%;
  color: var(--Element-main-theme);
}
.Direction-Icon:hover {
  width: 100%;
  height: 100%;
  color: var(--Element-secondary-theme);
}
.Direction-Icon:active {
  width: 100%;
  height: 100%;
  color: var(--Element-hi-light);
}


@media only screen and (max-width: 80rem) {
  .Canvas-W-H {
    width: fit-content;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }

  .W-size,
  .strokeWidth,
  .segments {
    display: flex;
    white-space: nowrap;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .var-inputs {
    color: var(--Element-main-theme);
    font-size: 1rem;
    padding-left: 0.2rem;
    width: 3em;
    background-color: var(--Secondary-main-theme);
  }
}
@media only screen and (max-width: 50rem) {

  .Bottom-Bar {
    padding: 1rem 1rem 0 1rem;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    color: white;
    background-color: var(--Main-theme);
    display: flex;
    justify-content: space-around;
    gap: 8vw;
  }
    .Canvas-Title,
  .Variable-Title,
  .Rotation-Title {
    visibility: collapse;
    position: fixed;
  }
  .Variables {
    font-size: 1rem;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: fit-content;
    color: var(--Element-main-theme);
    font-weight: 500;
    margin-bottom: max(1vh, 0.5rem);
    appearance: none;
  }
  .Canvas-W-H {
    width: fit-content;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: max(2vh, 1rem);
    margin: 0;
  }

  .Rotation {
    font-size: 1rem;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: fit-content;
    color: var(--Element-main-theme);
    font-weight: 500;
    margin-bottom: max(1vh, 0.5rem);
    appearance: none;
  }
}
/* @media screen and (max-height: 50rem){
  .Bottom-Bar {
    padding: 2vh 0 2vh 2vw;
    position: fixed;
    height: 20vh;
    bottom: 0;
    width: 100%;
    color: white;
    background-color: var(--Main-theme);
    display: flex;
    gap: 8vw;
    font-size: min(2vh,1rem);
  }
} */
@media only screen and (max-width: 40rem) {

  .Bottom-Bar {
    padding: 1vh 0 0 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    color: white;
    background-color: var(--Main-theme);
    display: flex;
    gap: 8vw;
  }
  .W-size,
.strokeWidth,
.segments {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
  font-size: 14px;
}

  .Canvas-W-H {
    width: fit-content;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: max(2vh, 1rem);
  }

  .Variables {
    font-size: 14px;
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: fit-content;
    color: var(--Element-main-theme);
    font-weight: 500;
    margin-bottom: max(1.5vh, 0.6rem);
  }
  .Rotation{
    font-size: 14px;
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: fit-content;
    color: var(--Element-main-theme);
    font-weight: 500;
    margin-bottom: max(2vh, 1rem);
  }

  .Variable-Input {
    appearance: none;
    background-color: transparent;
    width: 100px;
  }

  .Variable-Input::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    background-color: var(--Element-main-theme);
    border-radius: 4px;
    height: 0.5rem;
  }
  .Variable-Input::-webkit-slider-thumb {
    -webkit-appearance: none;
    border-radius: 50%;
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    margin-top: -0.20rem;
    background-color: var(--Element-secondary-theme);
  }
}
