.Layer-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: max(0.6rem, 2vw);
  margin-bottom: 7px;
  /* padding: 5zpx 0px; */
  width: 100%;
  height: 100%;
  position: relative;
  padding: 2px 8px;
}
.layer-overlay{
  position: absolute;
  width: 100%;
  height: inherit;
  max-width: inherit;
}
.Layer-container:hover {
  background-color: var(--Secondary-main-theme);
}
.Layer-active {
  background-color: var(--Secondary-main-theme);
}
.cover {
  z-index: 3;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.color-picker {
  z-index: 4;
  position: absolute;
  /* left: 0; */
  translate: -70% 55%;
}
.layer-color {
  width: 50%;
  min-width: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: transparent;
  color: white;
  height: 1rem;
  font-size: 1rem;
}


.layer-buttons {
  display: flex;
  gap: 13px;
}
.layer-button {
  color: var(--Element-main-theme);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
}
.layer-buttonShow {
  color: var(--Element-main-theme);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
}
.layer-more{
  color: var(--Element-main-theme);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  position: absolute;
  visibility: hidden;
}
.layer-icon {
  height: 100%;
  width: 100%;
}
.DefaultSizeBtns{
  display: flex;
  visibility: hidden;
  position: absolute;
  gap: 13px;
}
.layer-button:active{
  color: var(--Element-secondary-theme);
}

@media only screen and (max-width: 75rem){
  .layer-buttonShow {
    color: var(--Element-main-theme);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    position: absolute;
    visibility: hidden;
  }
  .layer-more{
    color: var(--Element-main-theme);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    position: static;
    visibility: visible;
  }
  .DefaultSizeBtns{
    z-index: 4;
    border-radius: 10px;
    padding: 7px;
    flex-direction: column;
    position: absolute;
    visibility: visible;
    top: 140%;
    right: 50%;
    background-color: var(--Element-hi-light);
  }
  .layer-buttons{
    flex-direction: column;
    place-items: center;
    position: relative;
  }
}